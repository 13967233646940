.container {
  @apply max-w-[460px] sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1220px] !important;
}

.narrow-container {
  @apply mx-auto max-w-[510px] md:max-w-[570px] lg:max-w-[610px] xl:max-w-[780px] 2xl:max-w-[1230px] !important;
}

.wide-container {
  @apply max-w-[95%];
}

.fixed-narrow-container {
  @apply max-w-[800px];
}

.fixed-narrow-container > div {
  @apply px-0 md:px-0 !important;
}
