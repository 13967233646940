@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import './fonts.css';
}

@layer components {
  @import './accordion.css';
  @import './actionsDropdown.css';
  @import './button.css';
  @import './carousel.css';
  @import './container.css';
  @import './dateTimePicker.css';
  @import './form.css';
  @import './giftDetails.css';
  @import './image.css';
  @import './listItem.css';
  @import './menu.css';
  @import './modal.css';
  @import './pagination.css';
  @import './stepper.css';
  @import './recaptcha.css';
  @import './grid.css';
  @import './arrows.css';
  @import './tooltip.css';
  @import './slider.css';
}

@layer utilities {
  @import './focus.css';
  @import './no-scrollbar.css';
}

html:has(.modal-overlay, .menu-overlay) {
  overflow: hidden;
}

body {
  font-family: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
