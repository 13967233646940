/* Pagination */

.rc-pagination {
  @apply flex flex-wrap items-center p-0 m-0 list-none text-sm;
}

.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-next,
.rc-pagination-jump-prev {
  @apply inline-flex items-center justify-center relative w-8 min-h-[32px] mx-1 px-1.5 rounded-full text-ivoryBlack transition-colors duration-[250ms] ease-in-out cursor-pointer focus-within:shadow-[0px_0px_0px_4px] focus-within:shadow-outline/50 focus-visible:outline-none focus-within:outline-current focus-within:outline-none focus-within:outline-0 !important;
}
.rc-pagination-item:hover,
.rc-pagination-prev:hover,
.rc-pagination-next:hover,
.rc-pagination-jump-next:hover,
.rc-pagination-jump-prev:hover {
  @apply bg-alabaster;
}

.rc-pagination-item > *,
.rc-pagination-prev > *,
.rc-pagination-next > *,
.rc-pagination-jump-next > *,
.rc-pagination-jump-prev > * {
  @apply px-1.5 text-ivoryBlack;
}

.rc-pagination-prev > svg,
.rc-pagination-next > svg,
.rc-pagination-jump-next > svg,
.rc-pagination-jump-prev > svg {
  @apply p-0;
}

.rc-pagination-item-active {
  @apply bg-alto;
}

.rc-pagination-disabled {
  @apply opacity-40 cursor-default;
}
.rc-pagination-disabled:hover {
  @apply bg-transparent;
}
