.ReactModalPortal {
  @apply fixed z-50 w-full h-full;
}

.modal-overlay {
  @apply fixed top-0 left-0 right-0 bottom-0 bg-secondary/50 flex flex-row items-center;
}

.modal-content {
  @apply max-h-full my-5 mx-5 sm:mx-auto bottom-auto [&>div]:p-10 bg-white overflow-auto rounded-[10px] outline-none;
}
