.arrow-right {
  @apply border-mostlyGreen relative border !h-[1px] !mt-[8px] w-full animate-grow-width;
}

.arrow-right::after {
  content: '';
  position: absolute;
  top: -6px;
  right: -4px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 12px solid #48785d;
}
