/* .tooltip {
  @apply m-[2px] mt-3.5 px-2 py-1 max-w-[300px] bg-ivoryBlack text-white text-xs font-medium rounded-md break-words;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(35, 31, 32) transparent;
} */

.rc-tooltip {
  @apply !opacity-100 font-medium;
}
