/* Stepper */

.rc-steps {
  @apply flex flex-row items-start;
}

.rc-steps-item {
  @apply flex justify-center flex-1 relative px-2;
}

.rc-steps-item-container {
  @apply inline-flex flex-col items-center justify-center flex-1 relative bg-transparent outline-current outline-none outline-0 border-none select-none align-middle appearance-none no-underline text-inherit px-4 py-6 mx-[-16px] my-[-24px] box-content;
}

.rc-steps-item:not(:first-child) .rc-steps-item-tail {
  @apply absolute block border-t border-t-[rgb(189,189,189)] top-9 h-[1px] !important;
  left: calc(-50% + 48px);
  right: calc(50% + 32px);
}

.rc-steps-item-icon {
  @apply flex items-center justify-center flex-shrink-0 bg-ivoryBlack/40 text-white w-6 h-6 rounded-[50%] text-center text-xs transition-colors duration-150 ease-in-out;
}

.rc-steps-item-process .rc-steps-item-icon,
.rc-steps-item-finish .rc-steps-item-icon {
  @apply bg-primary;
}

.rc-steps-icon {
  @apply flex items-center justify-center w-full h-full;
}

.rc-steps-icon > svg {
  @apply w-5 h-5;
}

.rc-steps-item-content {
  @apply w-full;
}

.rc-steps-item-title {
  @apply text-sm font-medium text-center text-ivoryBlack/60 mt-4;
}

.rc-steps-item-process .rc-steps-item-title,
.rc-steps-item-finish .rc-steps-item-title {
  @apply text-ivoryBlack;
}
