/* Menu */

.rc-menu > .rc-menu-item-active {
  @apply bg-transparent !important;
}

.rc-menu .rc-menu-item-selected {
  @apply bg-transparent !important;
}

.rc-menu .rc-menu-submenu-title {
  @apply bg-transparent text-inherit flex p-0 justify-between font-medium !important;
}

.rc-menu .rc-menu-sub {
  @apply mt-2 !important;
}

.rc-menu .rc-menu-submenu,
.rc-menu .rc-menu-item-group {
  @apply bg-transparent px-4 py-1.5 !important;
}

.rc-menu .rc-menu-submenu .rc-menu-item {
  @apply bg-transparent mx-6 pl-0 !important;
}

.rc-menu .rc-menu-item-group-list > .active {
  @apply bg-tertiary/50 !important;
}

.rc-menu .rc-menu-item-group-list > .active > a {
  @apply hover:text-white !important;
}

.rc-menu .rc-menu-item-group-list > .inactive {
  @apply hover:bg-tertiary/50  !important;
}

.rc-menu .rc-menu-item-group-list > .inactive > a {
  @apply hover:text-white  !important;
}

.rc-menu .rc-menu-item-group-title {
  @apply text-anotherGrey2 font-medium !important;
}

.menu-overlay {
  @apply fixed top-0 left-0 bottom-0 right-0 overflow-hidden;
}
