/* ActionsDropdown */

.rc-dropdown {
  @apply font-body text-base z-[20] !important;
}

.rc-dropdown .rc-menu {
  @apply text-ivoryBlack rounded-xl bg-white border-0 shadow-[rgba(93,89,89,0.2)_0px_5px_5px_-3px,_rgba(93,89,89,0.14)_0px_8px_10px_1px,_rgba(93,89,89,0.12)_0px_3px_14px_2px]  !important;
}

.rc-dropdown .rc-menu > .rc-menu-item {
  @apply bg-transparent outline-current outline-none outline-0 rounded-none cursor-pointer select-none align-middle appearance-none text-inherit font-body font-normal text-base flex justify-start items-center relative no-underline min-h-[48px] px-4 py-1.5 box-border whitespace-nowrap first:rounded-t-xl last:rounded-b-xl focus-within:bg-ivoryBlack/10 !important;
}

.rc-dropdown .rc-menu > .rc-menu-item > a {
  @apply focus-visible:outline-current focus-visible:outline-none focus-visible:outline-0  !important;
}

.rc-dropdown .rc-menu > .active {
  @apply bg-tertiary/50 !important;
}

.rc-dropdown .rc-menu > .active > a {
  @apply hover:text-white !important;
}

.rc-dropdown .rc-menu > .inactive {
  @apply hover:bg-tertiary/50  !important;
}

.rc-dropdown .rc-menu > .inactive > a {
  @apply hover:text-white  !important;
}
