/* DateTimePicker */

.react-datetime-picker__wrapper {
  @apply border-0 !important;
}

.react-datetime-picker,
.react-datetime-picker *,
.react-datetime-picker:before,
.react-datetime-picker:after {
  @apply box-content !important;
}

.react-datetime-picker__inputGroup__input {
  @apply focus-visible:outline-current focus-visible:outline-none focus-visible:outline-0 invalid:bg-transparent !important;
}
