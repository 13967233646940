/* Form */

.form-group {
  @apply inline-flex flex-col relative min-w-0 p-0 mb-4 md:mb-8 border-0 align-top w-full [&>label]:focus-within:text-primary [&>legend]:focus-within:text-primary;
}

.form-label,
.form-legend {
  @apply relative p-0 mb-[5px] text-base text-[18px] leading-[27px] font-bold text-[#444444] transition-colors duration-200 ease-in-out;
}

.form-help-text {
  @apply !m-0 !mb-[10px] text-[14px] leading-[21px] text-anotherGrey2;
  @apply last:!mb-[0px];
}

.form-description-text {
  @apply !m-0 !mb-[10px] !text-[14px] !leading-[21px] md:!text-[16px] md:!leading-[24px] font-medium text-shark;
}

.form-error-message {
  @apply !m-0 text-[14px] !mb-[10px] leading-[21px] text-error;
  @apply last:!mb-[0px];
}

.form-label + .form-input-wrapper {
  @apply mt-0 mb-[10px];
}

.form-input-wrapper {
  @apply inline-flex items-center relative w-full rounded-[10px] mb-2 px-4 py-2 bg-transparent border border-silver text-base font-normal leading-[1.4375rem] text-ivoryBlack cursor-text box-border focus-within:shadow-[0px_0px_0px_4px] focus-within:shadow-outline/50 focus-visible:outline-none;
}

.form-input-wrapper-disabled {
  @apply bg-alabaster;
}

.ttc-form-input {
  @apply block min-w-0 w-full pt-1 pb-[5px] px-0 mb-0 h-[1.4375em] bg-transparent text-base font-normal leading-[1.4375rem] text-inherit border-none box-content focus:outline-current focus:outline-none focus:outline-0 disabled:text-anotherGrey2;
}

.form-input-adornment {
  @apply flex h-[0.01em] max-h-[2em] items-center whitespace-nowrap text-ivoryBlack/60 ml-2;
}
