/* GiftDetails component */

.gift-details-wrapper {
  @apply grid grid-cols-2 md:block !important;
}

.gift-detail {
  @apply mb-4 !important;
}

.gift-detail-title,
.gift-detail-description {
  @apply w-full !important;
}

.gift-detail-title {
  @apply font-semibold md:sr-only !important;
}

.gdd-to,
.gdd-from,
.gdd-quantity {
  @apply md:absolute md:w-auto md:text-tomThumb md:font-semibold !important;
}

.gdd-to {
  @apply md:top-[31%] md:left-[16%] !important;
}

.gdd-from {
  @apply md:top-[36%] md:left-[16%] !important;
}

.gdd-quantity {
  @apply md:top-[41.5%] md:left-[33%] md:uppercase !important;
}

.gdd-message {
  @apply md:absolute md:w-full md:max-w-[180px] md:top-[45%] md:left-[60%] md:font-display md:text-white md:text-xs md:text-center !important;
}
