/* Carousel */
.slick-prev,
.slick-next {
  @apply text-base z-10 text-primary !important;
}
.slick-arrow:before {
  @apply hidden !important;
}

.slick-arrow.slick-disabled {
  @apply hidden !important;
}

.slick-controls-outside .slick-prev {
  @apply -left-5 lg:-left-10 !important;
}
.slick-controls-outside.slick-controls-small-arrows .slick-prev {
  @apply -left-3 lg:-left-5 !important;
}
.slick-controls-inside .slick-prev {
  @apply left-5 lg:left-8 !important;
}

.slick-controls-outside .slick-next {
  @apply -right-5 lg:-right-10 !important;
}
.slick-controls-outside.slick-controls-small-arrows .slick-next {
  @apply -right-3 lg:-right-5 !important;
}
.slick-controls-inside .slick-next {
  @apply right-5 lg:right-8 !important;
}

.custom-slick-dots {
  @apply bottom-6 absolute list-none !flex flex-row justify-center gap-3 text-center p-0 w-full;
}

.custom-slick-dots li button {
  @apply w-5 h-5 block text-[0px] border-lightRed border rounded-full bg-white;
  @apply focus:shadow-[0px_0px_0px_4px] focus:shadow-outline/50 focus-visible:outline-none;
}

.custom-slick-dots li.slick-active button {
  @apply bg-lightRed2;
}

.slick-controls-outside .custom-slick-dots {
  @apply -bottom-8;
}

.home-slide:before {
  @apply absolute inset-0;
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.33),
    transparent 50%
  );
  content: '';
}

/* The classes bellow allow Slick Slider slides to use height: 100% */

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide > div {
  height: 100%;
}
