/* Accordion */

.rc-collapse {
  @apply border-none bg-transparent !important;
}

.rc-collapse > .rc-collapse-item {
  @apply border-t-0 !important;
}

.rc-collapse > .rc-collapse-item:last-child > .rc-collapse-content {
  @apply border-b-0 !important;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  @apply relative flex px-0 pt-0 pb-5 mt-5 mb-5 text-inherit border-b-2 border-solid border-alto !important;
}

.rc-collapse
  > .rc-collapse-item
  > .rc-collapse-header
  > .rc-collapse-expand-icon {
  @apply order-2;
}

.rc-collapse
  > .rc-collapse-item
  > .rc-collapse-header
  > .rc-collapse-header-text {
  @apply mr-4 !important;
}

.rc-collapse-content {
  @apply px-0 border-b-2 border-solid border-alto text-ivoryBlack !important;
}

.rc-collapse-content > .rc-collapse-content-box {
  @apply my-0 !important;
}
