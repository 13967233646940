.list-styled {
  @apply pl-6;
}

.list-styled > li {
  @apply relative before:bg-secondary before:absolute before:w-2 before:h-2 before:rounded-full before:top-2 before:-left-4;
}

.list-styled.black > li {
  @apply relative before:bg-black;
}

.list-check > li {
  @apply flex flex-row items-center !mb-[15px] lg:!mb-5;
}

.list-check > li::before {
  @apply content-[''] bg-[url("/img/icons/list-check.svg")] bg-no-repeat mr-[10px] lg:mr-[20px] bg-mostlyGreen inline-block w-[25px] h-[25px] rounded-full shrink-0 self-center;
}

.list-check.large > li::before {
  @apply shrink-0 bg-cover bg-no-repeat mr-[10px] md:mr-[20px] lg:w-[50px] lg:h-[50px] w-[25px] h-[25px] rounded-full;
}

.list-check.focus > li {
  @apply flex items-center !mb-[15px] lg:!mb-5 text-[14px] lg:text-[20px];
}

.list-check.focus > li::before {
  @apply shrink-0 content-[''] bg-[url("/img/icons/list-check-focus.svg")] bg-cover bg-no-repeat mr-[5px] md:mr-[10px] bg-white inline-block lg:w-[50px] lg:h-[50px] w-[30px] h-[30px] rounded-full;
}
